import kinTheConqueror from './assets/Kin-The-Conqueror.png'

const KinTheConqueror = {
    name: 'Kin the Conqueror',
    health: 400,
    defaultHealth: 400,
    gold: 100,
    attack: 30,
    imageUrl: kinTheConqueror,
    };

export default KinTheConqueror;