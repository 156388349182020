import enemy1Image from './assets/enemy1.png';
const enemy1 = {
  name: 'ByteBot',
  defaultHealth: 100,
  health: 100,
  gold: 10,
  attack: 10,
  imageUrl: enemy1Image,
};

export default enemy1;
