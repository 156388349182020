import enemy2Image from './assets/enemy2.png';

const enemy2 = {
    name: 'Cosmic Guardian of Pain Titan',
    health: 200,
    defaultHealth: 200,
    gold: 20,
    attack: 20,
    imageUrl: enemy2Image,
  };

  export default enemy2;
